import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { crownItems } from "../../data";
import { shortName } from "../../utils";
import { ReactComponent as GreenSolSymbol } from '../../assets/sol-symbol-green.svg';
import { ReactComponent as GraySolSymbol } from '../../assets/sol-symbol-gray.svg';
import { ReactComponent as RedSolSymbol } from '../../assets/sol-symbol-red.svg';
import { ReactComponent as BlueSolSymbol } from '../../assets/sol-symbol-blue.svg';
import blankUserIcon from '../../assets/blank-user.svg';
import useSound from "use-sound";

interface IProps {
    index: number;
    rank: number;
    avatar: string | undefined;
    username: string;
    publicKey: string;
    totalProfit: number;
    totalVolume: number;
    totalDailyReward: number;
}

const TableRow = (props: IProps) => {
    const {
        index,
        rank,
        avatar,
        username,
        publicKey,
        totalProfit,
        totalVolume,
        totalDailyReward,
    } = props;

    const navigate = useNavigate();
    const wallet = useWallet();
    const [isCurrentUser, setIsCurrentUser] = useState<boolean>(false);
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });

    useEffect(() => {
        if (wallet?.publicKey) {
            if (publicKey == wallet?.publicKey?.toString()) {
                setIsCurrentUser(true);
            } else {
                setIsCurrentUser(false);
            }
        } else {
            setIsCurrentUser(false);
        }
    }, [publicKey, wallet?.publicKey]);

    return (
        <div className={`
            flex items-center w-full min-h-[40px] md:min-h-[65px] rounded-[10px]
            ${rank < 3 ? `${crownItems[rank].title.toLowerCase()}-item` : isCurrentUser ? 'current-user-item' : index % 2 == 0 ? ' border border-[#11161B] bg-[rgba(17,22,27,0.30)] shadow-[inset_0px_0px_6px_6px_#11161B] ' : ''}
        `}>
            {/* Rank */}
            <div className="flex items-center gap-[5px] md:gap-[10px] w-full max-w-[62px] md:max-w-[146px] pl-[14px] md:pl-[26px]">
                <div className="flex justify-center items-center w-[25.5px] md:w-[41px] text-[12px] md:text-[20px] font-normal leading-[17px] md:leading-[28px] relative">
                    <span>{rank + 1}</span>
                    {
                        rank < 3 && (
                            <div className="w-[25.5px] md:w-[41px] absolute">
                                <img
                                    src={crownItems[rank].icon}
                                    alt={crownItems[rank].title}
                                    className="w-full rounded-full"
                                />
                            </div>
                        )
                    }
                </div>
                {
                    isCurrentUser && (
                        <span className="text-[8px] md:text-[14px] text-[#808080] font-normal leading-[11px] md:leading-[19px]">You</span>
                    )
                }
            </div>

            {/* Player */}
            <div
                className="w-full max-w-[264px] cursor-pointer"
                onClick={() => navigate(`/user-stats/${publicKey}`)}
                onMouseEnter={() => playHover()}
            >
                <div className="flex items-center gap-[4px] md:gap-[10px] w-full">
                    <div className="flex justify-center items-center w-[20px] md:w-[40px] h-[20px] md:h-[40px]">
                        <img
                            src={avatar || blankUserIcon}
                            alt="avatar"
                            className="w-full h-full rounded-full"
                        />
                    </div>

                    <span className="text-[10px] md:text-[14px] font-medium leading-[14px] md:leading-[20px]">
                        {shortName(username || publicKey)}
                    </span>
                </div>
            </div>

            {/* Net Profit */}
            <div className="w-full max-w-[171px] text-center">
                <div className="flex justify-center items-center gap-[3PX] MD:gap-[5px] w-[73px]">
                    <span className={`text-[10px] md:text-[14px] font-normal leading-[14px] md:leading-[19px] ${totalProfit > 0 ? 'text-[#46FF78]' : totalProfit == 0 ? 'text-[#808080]' : 'text-[#FF6363]'}`}>
                        {(totalProfit / LAMPORTS_PER_SOL).toFixed(2)}
                    </span>
                    <div className="flex justify-center items-center w-[11px]">
                        {
                            totalProfit > 0 ? (
                                <GreenSolSymbol className="w-full" />
                            ) : totalProfit == 0 ? (
                                <GraySolSymbol className="w-full" />
                            ) : (
                                <RedSolSymbol className="w-full" />
                            )
                        }
                    </div>
                </div>
            </div>

            {/* Wagered */}
            <div className="w-full max-w-[166px] text-center">
                <div className="w-[68px] text-[10px] md:text-[14px] font-normal leading-[14px] md:leading-[19px]">
                    {(totalVolume / LAMPORTS_PER_SOL).toFixed(2)}
                </div>
            </div>

            {/* Race Reward */}
            <div className="w-full max-w-[130px] text-center">
                <div className="flex justify-center items-center gap-[5px] w-[80px] md:w-[96px]">
                    <span className="text-[10px] md:text-[14px] font-normal leading-[14px] md:leading-[19px] wager-race-reward">
                        {(totalDailyReward / LAMPORTS_PER_SOL).toFixed(2)}
                    </span>
                    <div className="flex justify-center items-center w-[14px]">
                        <BlueSolSymbol className="w-full" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableRow;