import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Menu from "@mui/material/Menu";
import { useWallet } from "@solana/wallet-adapter-react";
import axios from "axios";
import TableRow from "./TableRow";
import { sortItems } from "../../data";
import { SortTypeEnum, TimeFilterTypeEnum } from "../../enums";
import { IDailyRewardInfo, IUserRankInfo } from "../../interfaces/Leaderboard";
import { ReactComponent as ArrowDownIcon } from "../../assets/arrow-down-filled.svg";
import useSound from "use-sound";
import "./index.scss";

const Leaderboard = () => {
  const wallet = useWallet();
  const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortType, setSortType] = useState<SortTypeEnum>(
    SortTypeEnum.TotalVolume
  );
  const [timeFilterType, setTimeFilterType] = useState<TimeFilterTypeEnum>(
    TimeFilterTypeEnum.Today
  );
  const [userRankInfo, setUserRankInfo] = useState<IUserRankInfo[]>([]);
  const [dailyRewardInfo, setDailyRewardInfo] = useState<IDailyRewardInfo[]>(
    []
  );
  const [currentUserRank, setCurrentUserRank] = useState<number>(-1);
  const [currentUserRankInfo, setCurrentUserRankInfo] = useState<
    IUserRankInfo | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const open = Boolean(anchorEl);
  const handleOpenSort = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSort = () => {
    setAnchorEl(null);
  };

  const initialize = async () => {
    try {
      setIsLoading(true);

      const config = {
        params: {
          timeFilterType,
        },
      };

      const [leaderboardInfoRes, allUsersRes] = await Promise.all([
        axios.get("/api/leaderboard", config),
        axios.post("/api/u/getAllUsers"),
      ]);

      const [leaderboardInfo, allUsers] = [
        leaderboardInfoRes.data,
        allUsersRes.data,
      ];

      const userRankInfoRes: any[] = leaderboardInfo.userRankInfo;
      const dailyRewardInfo: any[] = leaderboardInfo.dailyRewardInfo;

      const userRankInfo: IUserRankInfo[] = [];
      await Promise.all(
        userRankInfoRes.map(async (item) => {
          try {
            const rewardAmount = dailyRewardInfo
              .filter((x) => x.publicKey == item.publicKey)
              .reduce((sum, obj) => sum + obj.amount, 0);

            const rowData = {
              username: allUsers.find((x: any) => x.publicKey == item.publicKey)
                ?.username,
              userAvatar: allUsers.find(
                (x: any) => x.publicKey == item.publicKey
              )?.userAvatar,
              publicKey: item.publicKey,
              userProfit: item.userProfit,
              volume: item.volume,
              reward: rewardAmount,
            };
            userRankInfo.push(rowData);
          } catch {}
        })
      );

      if (sortType == SortTypeEnum.TotalVolume) {
        userRankInfo.sort((a, b) => b.volume - a.volume);
      } else {
        userRankInfo.sort((a, b) => b.userProfit - a.userProfit);
      }

      setUserRankInfo(userRankInfo);
      setDailyRewardInfo(dailyRewardInfo);

      setIsLoading(false);
    } catch (e) {
      console.log("e", e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);

    if (userRankInfo.length > 0 && wallet?.publicKey) {
      const currentUserRank = userRankInfo.findIndex(
        (x) => x.publicKey == wallet?.publicKey?.toString()
      );
      if (currentUserRank >= 0) {
        setCurrentUserRank(currentUserRank);
        setCurrentUserRankInfo(userRankInfo[currentUserRank]);
      } else {
        setCurrentUserRankInfo(undefined);
      }
    } else {
      setCurrentUserRankInfo(undefined);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [userRankInfo, wallet?.publicKey]);

  useEffect(() => {
    setIsLoading(true);
    setUserRankInfo((current) => {
      if (sortType == SortTypeEnum.TotalVolume) {
        return [...current.sort((a, b) => b.volume - a.volume)];
      } else {
        return [...current.sort((a, b) => b.userProfit - a.userProfit)];
      }
    });
    setIsLoading(false);
  }, [sortType]);

  useEffect(() => {
    initialize();
  }, [timeFilterType]);

  return (
    <div className="flex flex-col md:flex-row w-full min-h-[calc(100vh-59px)] md:min-h-[calc(100vh-70px)] bg-[#090C0E] rounded-tr-[10px]">
      <div className="w-full max-w-[877px] px-[10px] md:px-0 mt-[60px] md:mt-[80px] mx-auto leaderboard">
        {/* Time filter & Sort */}
        <div className="flex justify-between items-center">
          {/* Time filter */}
          <div className="flex items-center gap-[18px] md:gap-[30px]  time-filter-box">
            <button
              disabled={isLoading}
              className={`hover-effect text-[10px] md:text-[16px] ${
                timeFilterType == TimeFilterTypeEnum.Today
                  ? "text-white"
                  : "text-[#808080]"
              } font-medium leading-[14px] md:leading-[23px]`}
              onClick={() => setTimeFilterType(TimeFilterTypeEnum.Today)}
              onMouseEnter={() => playHover()}
            >
              Today
            </button>

            <button
              disabled={isLoading}
              className={`hover-effect text-[10px] md:text-[16px] ${
                timeFilterType == TimeFilterTypeEnum.Week
                  ? "text-white"
                  : "text-[#808080]"
              } font-medium leading-[14px] md:leading-[23px]`}
              onClick={() => setTimeFilterType(TimeFilterTypeEnum.Week)}
              onMouseEnter={() => playHover()}
            >
              Week
            </button>

            <button
              disabled={isLoading}
              className={`hover-effect text-[10px] md:text-[16px] ${
                timeFilterType == TimeFilterTypeEnum.All
                  ? "text-white"
                  : "text-[#808080]"
              } font-medium leading-[14px] md:leading-[23px]`}
              onClick={() => setTimeFilterType(TimeFilterTypeEnum.All)}
              onMouseEnter={() => playHover()}
            >
              All
            </button>
          </div>

          {/* Sort */}
          <div className="flex items-center gap-[6px] md:gap-[10px] sort-box">
            <span className="text-[10px] md:text-[16px] text-[#808080] font-medium leading-[14px] md:leading-[23px]">
              Sort by:
            </span>
            <button
              className="flex justify-center items-center w-[62px] md:w-[100px] h-[17px] md:h-[27px] bg-[#11161B] shadow-[inset_0px_0px_1px_#808080] rounded-[6px] relative"
              onClick={handleOpenSort}
              onMouseEnter={() => playHover()}
            >
              <span className="text-[8px] md:text-[14px] font-medium leading-[11px] md:leading-[20px]">
                {sortItems[sortType].title}
              </span>
              <div className="flex justify-end items-center w-full h-full absolute top-0 left-0 px-[6px] md:px-[7px]">
                <ArrowDownIcon
                  className={`w-[5px] md:w-[8px] h-[5px] md:h-[8px] ${
                    open ? "rotate-180" : "rotate-0"
                  }`}
                />
              </div>
            </button>

            <Menu
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "10px",
                  minWidth: "100px",
                  marginTop: "4px",
                  background: "#11161B",
                  boxShadow: "inset 0px 0px 4px #808080",
                },
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseSort}
            >
              <div className="flex flex-col justify-center gap-[5px] text-[8px] md:text-[14px] text-white font-medium leading-[11px] md:leading-[20px] px-[5px]">
                {sortItems.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`w-full p-[2px_10px] hover:bg-[#808080]/60 rounded-[8px] cursor-pointer ${
                        sortType == item.value ? "bg-[#808080]/60" : ""
                      }`}
                      onClick={() => {
                        setSortType(item.value);
                        handleCloseSort();
                      }}
                      onMouseEnter={() => playHover()}
                    >
                      {item.title}
                    </div>
                  );
                })}
              </div>
            </Menu>
          </div>
        </div>

        {/* Table */}
        <div className="w-full mt-[8px] md:mt-[12px]">
          {/* Table header */}
          <div className="flex items-center w-full h-[52px] md:h-[66px] text-[10px] md:text-[16px] text-[#808080] font-normal leading-[12px] md:leading-[19px] table-header-box">
            <div className="w-full max-w-[62px] md:max-w-[146px] pl-[16px] md:pl-[20px]">
              Rank
            </div>
            <div className="w-full max-w-[264px]">
              <span className="pl-[18px] md:pl-[27px]">Player</span>
            </div>
            <div className="w-full max-w-[171px] text-center">
              <div className="w-[73px] text-center">Net Profit</div>
            </div>
            <div className="w-full max-w-[166px] text-center">
              <div className="w-[68px] text-center">Wagered</div>
            </div>
            <div className="w-full max-w-[130px] text-center">
              <div className="w-[80px] md:w-[96px] wager-race-reward">
                NFT Reward
              </div>
            </div>
          </div>

          {/* Table body */}
          <div className="flex flex-col items-center w-full max-h-[400px] md:max-h-[520px] min-h-[40px] md:min-h-[65px] overflow-y-auto no-scroll-bar relative">
            {isLoading && (
              <div className="flex justify-center items-center w-full h-full absolute">
                <ClipLoader size={24} color="#ffffff" />
              </div>
            )}
            {currentUserRankInfo !== undefined && currentUserRank > 7
              ? userRankInfo
                  .filter((x) => x.publicKey != currentUserRankInfo.publicKey)
                  .map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        index={index}
                        rank={index < currentUserRank ? index : index + 1}
                        avatar={item.userAvatar}
                        username={item.username}
                        publicKey={item.publicKey}
                        totalProfit={item.userProfit}
                        totalVolume={item.volume}
                        totalDailyReward={item.reward}
                      />
                    );
                  })
              : userRankInfo.map((item, index) => {
                  return (
                    <TableRow
                      key={index}
                      index={index}
                      rank={index}
                      avatar={item.userAvatar}
                      username={item.username}
                      publicKey={item.publicKey}
                      totalProfit={item.userProfit}
                      totalVolume={item.volume}
                      totalDailyReward={item.reward}
                    />
                  );
                })}
          </div>

          {/* Table footer */}
          {currentUserRankInfo !== undefined && currentUserRank > 7 && (
            <TableRow
              index={currentUserRank}
              rank={currentUserRank}
              avatar={currentUserRankInfo.userAvatar}
              username={currentUserRankInfo.username}
              publicKey={currentUserRankInfo.publicKey}
              totalProfit={currentUserRankInfo.userProfit}
              totalVolume={currentUserRankInfo.volume}
              totalDailyReward={currentUserRankInfo.reward}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
