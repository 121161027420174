import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Highlighter from "react-highlight-words";
import { Button, Input, message, Popover, Space, Switch, Table, Upload } from "antd";
import type { UploadProps } from 'antd';
import TextArea from "antd/es/input/TextArea";
import { DeleteOutlined, InboxOutlined, SearchOutlined } from '@ant-design/icons';
import { IAnnouncement } from "../../../interfaces/Announcement";
import axios from "axios";

const { Dragger } = Upload;
let searchInput: any;

const AnnouncementAdmin = () => {
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<string>('');
    const [searchText, setSearchText] = useState<string>('');
    const [searchedColumn, setSearchedColumn] = useState<string>('');
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const [announcements, setAnnouncements] = useState<IAnnouncement[]>([]);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const initialize = async () => {
        try {
            const announcements = (await axios.get('/api/announcement/getAll'))?.data;
            setAnnouncements(Array.isArray(announcements) ? announcements : []);
        } catch { }
    }

    const handleManageShow = async (announcementId: string) => {
        try {
            await axios.post('/api/announcement/manageShow', { announcementId });
            await initialize();
        } catch { }
    }

    const handleDelete = async (announcementId: string) => {
        try {
            await axios.post('/api/announcement/delete', { announcementId });
            await initialize();
        } catch { }
    }

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownOpenChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            responsive: ["sm"],
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            responsive: ["sm"],
            ...getColumnSearchProps('description'),
            render: (description: string) => (
                <div>
                    {description.length > 30 ? description.slice(0, 30) + "..." : description}
                </div>
            ),
        },
        {
            title: 'Image',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            responsive: ["sm"],
            render: (imageUrl: string) => (
                <div>
                    <img src={imageUrl} className="h-[50px]" />
                </div>
            ),
        },
        {
            title: 'Show',
            dataIndex: 'isShown',
            key: 'isShown',
            align: 'center',
            width: 'auto',
            responsive: ["sm"],
            render: (isShown: any, record: any) => {
                return (
                    <Switch
                        size="small"
                        checked={isShown == true}
                        onChange={(_) => handleManageShow(record._id)}
                    />
                )
            },
        },
        {
            title: 'Remove',
            dataIndex: 'remove',
            key: 'remove',
            align: 'center',
            width: 'auto',
            responsive: ["sm"],
            render: (_: any, record: any) => {
                return (
                    <Popover
                        placement="topLeft"
                        title="Confirm to remove"
                        content={
                            <div className="flex items-center gap-[20px]">
                                <button
                                    className="underline"
                                    onClick={() => handleDelete(record._id)}
                                >
                                    Yes
                                </button>
                                <button
                                    className="underline"
                                    onClick={() => setSelectedId(null)}
                                >
                                    No
                                </button>
                            </div>
                        }
                        trigger="click"
                        open={selectedId == record._id}
                        onOpenChange={() => setSelectedId(record._id)}
                    >
                        <Button
                            ghost
                            danger
                            className='flex justify-center items-center mx-auto'
                        >
                            <DeleteOutlined />
                        </Button>
                    </Popover>
                )
            },
        },
    ];

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        action: '/api/announcement/uploadImage',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
                const imageUrl = info.file.response?.url;
                setImageUrl(imageUrl);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('')
    };

    const handleCreate = async () => {
        try {
            if (!title) {
                toast.warn('Please input a title');
                return;
            }

            if (!description) {
                toast.warn('Please input a description');
                return;
            }

            // if (!imageUrl) {
            //     toast.warn('Please upload an image');
            //     return;
            // }

            setIsProcessing(true);

            const data = { title, description, imageUrl };
            await axios.post('/api/announcement/create', data);
            await initialize();

            setTitle('');
            setDescription('');
            setImageUrl('');
            toast.success('Successful to creat a new announcement');
        } catch (e) {
            console.log(e);
            toast.error('Failed to create a new announcement');
        }

        setIsProcessing(false);
    }

    useEffect(() => {
        initialize();
    }, []);

    return (
        <div className="flex flex-col items-center w-full mt-[20px]">
            <h1 className="text-[20px] font-medium mt-5">Manage Announcement</h1>

            <div className="flex flex-col xl:flex-row w-full gap-[20px] px-[50px] mt-[20px]">
                {/* Announcement list */}
                <div className="flex-grow">
                    <Table
                        columns={columns as any}
                        dataSource={announcements}
                        scroll={{ x: 1000 }}
                        loading={isProcessing}
                        className="w-full"
                    />
                </div>

                {/* Create a new announcement */}
                <div className='flex flex-col gap-[20px] w-full max-w-[520px]'>
                    <div className="flex flex-col w-full">
                        <div>Title</div>
                        <Input
                            value={title}
                            className="w-full"
                            onChange={e => setTitle(e?.target?.value)}
                        />
                    </div>

                    <div className="flex flex-col w-full">
                        <div>Description</div>
                        <TextArea
                            value={description}
                            className="w-full"
                            rows={5}
                            onChange={e => setDescription(e?.target?.value)}
                        />
                    </div>

                    <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text !text-white">Click or drag file to this area to upload</p>

                        {
                            imageUrl !== '' && imageUrl !== undefined && (
                                <img src={imageUrl} className="h-full mx-auto" />
                            )
                        }
                    </Dragger>

                    <Button
                        loading={isProcessing}
                        disabled={isProcessing}
                        type='primary'
                        className='bg-blue-500 mt-[20px]'
                        onClick={() => handleCreate()}
                    >
                        Upload
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AnnouncementAdmin;