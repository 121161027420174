import React, { useEffect, useRef, useState } from "react";
import Confetti from "react-confetti";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import * as anchor from "@project-serum/anchor";
import { Connection, LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import axios from "axios";
import useSound from "use-sound";
import { CombinedReducer } from "../../store";
import User from "../../interfaces/User";
import { Sockets } from "../../reducers/sockets";
import arrowUpIcon from "../../assets/arrow_up.svg";
// @ts-ignore
import Slide from 'react-reveal/Slide';

enum FundTypeEnum {
    Deposit,
    Withdraw
}

enum NetworkStatusEnum {
    Good,
    Warning,
    Bad,
}

interface IProps {
    show: boolean;
    handleShow: (status: boolean) => void
}

const connection = new Connection(process.env.REACT_APP_RPC_URL!, "confirmed");
const servicePublicKey = new PublicKey(process.env.REACT_APP_PUBKEY!);

const FundModal = (props: IProps) => {
    const { show, handleShow } = props;
    const wallet = useWallet();
    const [fundType, setFundType] = useState<FundTypeEnum>(FundTypeEnum.Deposit);
    const [amount, setAmount] = useState<number | null>(0);
    const [withdrawStatus, setWithdrawStatus] = useState("none");
    const [withdrawTxHash, setWithdrawTxHash] = useState("");
    const [isWithdrawTimerStarted, setIsWithdrawTimerStarted] = useState<boolean>(false);
    const [seconds, setSeconds] = useState<number>(0);
    const [depositStatus, setDepositStatus] = useState("none");
    const [networkStatus, setNetworkStatus] = useState<NetworkStatusEnum>(NetworkStatusEnum.Good);
    const [playWin] = useSound("/sound/win.mp3", { volume: 1 });
    const [playDeposit] = useSound("/sound/deposit.mp3", { volume: 1 });
    const [playWithdraw] = useSound("/sound/withdraw.mp3", { volume: 0.5 });
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });

    const user = useSelector<CombinedReducer, User>((state) => state.user);
    const sockets = useSelector<CombinedReducer, Sockets>((state) => state.sockets);

    const initialize = async () => {
        try {
            const networkStatusConfig = (await axios.get('/api/leaderboard/getNetworkStatus')).data;
            console.log({ networkStatusConfig });

            if (!networkStatusConfig) {
                setNetworkStatus(NetworkStatusEnum.Good);
            } else {
                setNetworkStatus(networkStatusConfig.networkStatus);
            }
        } catch { }
    }

    const deposit = async () => {
        if (!(user && user?.publicKey && wallet?.publicKey)) {
            return toast.warn("Please connect wallet.");
        }

        if (!amount || amount < 0) {
            return toast.warn("Invalid amount");
        }

        if (amount < 0.01 * LAMPORTS_PER_SOL)
            return toast.error("Minimum deposit amount is 0.01");

        try {
            const transaction = new Transaction();

            transaction.add(
                anchor.web3.ComputeBudgetProgram.setComputeUnitPrice({
                    microLamports: 245000,
                })
            );
            transaction.add(
                anchor.web3.ComputeBudgetProgram.setComputeUnitLimit({
                    units: 800000,
                })
            );

            transaction.add(
                SystemProgram.transfer({
                    fromPubkey: wallet.publicKey,
                    toPubkey: servicePublicKey,
                    lamports: amount,
                })
            );

            const tx = await wallet.sendTransaction(transaction, connection);
            console.log({ tx });

            setDepositStatus("sent");
        } catch (e: any) {
            toast.error(e.response.data.message.toString());
            setDepositStatus("failed");
        }
    };

    const requestWithdraw = async () => {
        if (!(user && user?.publicKey && wallet?.publicKey)) {
            return toast.warn("Please connect wallet.");
        }

        if (!amount || amount < 0) {
            return toast.warn("Invalid amount");
        }

        if (amount < 0.01 * LAMPORTS_PER_SOL)
            return toast.error("Minimum withdraw is 0.01 SOL");
        if (amount > user?.balance)
            return toast.error("Balance needs to be higher than the withdraw amount");

        try {
            setWithdrawStatus("withdrawing");
            setIsWithdrawTimerStarted(true);
            setSeconds(60);
            const { txhash } = (
                await axios.post("/api/u/requestWithdraw", { amount: amount })
            ).data;
            setWithdrawStatus("withdrawn");
            setWithdrawTxHash(txhash);
        } catch (e: any) {
            toast.error(e.response.data.message.toString());
        }
    };

    const handleFund = async () => {
        if (fundType == FundTypeEnum.Deposit) {
            await deposit();
            playDeposit();
        } else {
            await requestWithdraw();
            playWithdraw();
        }
    }

    const inputAmount = (input: string) => {
        if (!input) return setAmount(null);

        if (Number(input) >= 0) setAmount(Number(input) * LAMPORTS_PER_SOL);
        else setAmount(0);
    };

    const onCompleteTimer = () => {
        if (isWithdrawTimerStarted) {
            if (withdrawStatus !== "withdrawn") {
                setWithdrawStatus("unknown");
            }
        }
    }

    const handleShowModal = (status: boolean) => {
        setDepositStatus("none");
        setWithdrawStatus("none");
        handleShow(status);
    }

    useEffect(() => {
        if (depositStatus === "validated") {
            playWin();
        }
    }, [depositStatus]);


    useEffect(() => {
        if (show) {
            // document.body.style.overflow = "hidden"
        } else {
            // document.body.style.overflow = "visible"
        }
    }, [show]);


    useEffect(() => {
        let myInterval: any;

        if (isWithdrawTimerStarted) {
            myInterval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }

                if (seconds == 0) {
                    clearInterval(myInterval);
                    onCompleteTimer();
                }
            }, 1000)
        } else {
            clearInterval(myInterval);
        }

        return () => {
            clearInterval(myInterval);
        };
    }, [seconds]);

    useEffect(() => {
        if (!(sockets && sockets.user)) return;

        sockets.user.on("balanceChange", (amount: number, fromDeposit: boolean) => {
            if (fromDeposit) {
                setDepositStatus("validated");
            }
        });
    }, [sockets]);

    useEffect(() => {
        initialize();
    }, []);

    return (
        <Slide top duration={500} when={show}>
            <div
                className="fund-modal fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] sm:bg-black/50 z-[1040] overflow-y-auto hidden justify-center"
                style={{ display: show ? "flex" : "none" }}
            >
                {
                    depositStatus === "validated" && (
                        <Confetti tweenDuration={1000} width={window.innerWidth} height={window.innerHeight} />
                    )
                }

                <div className="flex m-0 sm:m-4 w-full min-h-[calc(100vh-6rem)] justify-center items-start sm:items-center">
                    <div className="modal-wrapper !mt-[59px] !p-[30px_35px_0px] sm:!p-[30px_35px_30px] !max-w-full sm:!max-w-fit !shadow-none sm:!shadow-[0px_0px_10px_#00a5fe] border-b-4 border-solid border-[#00A5FE] sm:border-b-0 !rounded-tr-none !rounded-tl-none sm:!rounded-[7px]">
                        <button
                            className="hidden sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
                            onClick={() => handleShowModal(false)}
                            onMouseEnter={() => playHover()}
                        >
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 16L16 1M1 1L16 16" stroke="#BEBEBE" strokeWidth="2" />
                            </svg>
                        </button>

                        <div>
                            <div className="flex justify-center items-center w-full">
                                <button
                                    className={`fund-modal-tab-item w-[160px] h-[61px] text-[18px] font-medium ${fundType == FundTypeEnum.Deposit ? 'active' : ''}`}
                                    onClick={() => setFundType(FundTypeEnum.Deposit)}
                                    onMouseEnter={() => playHover()}
                                >
                                    Deposit
                                </button>

                                <button
                                    className={`fund-modal-tab-item w-[160px] h-[61px] text-[18px] font-medium ${fundType == FundTypeEnum.Withdraw ? 'active' : ''}`}
                                    onClick={() => setFundType(FundTypeEnum.Withdraw)}
                                    onMouseEnter={() => playHover()}
                                >
                                    Withdraw
                                </button>
                            </div>

                            <input
                                autoFocus
                                type={'number'}
                                disabled={depositStatus !== "none" || withdrawStatus !== "none"}
                                className="bg-[#1D262F] rounded-[10px] w-full mt-[35px] h-[37px] text-[18px] font-medium pl-[19px] outline-none"
                                value={amount === null ? "" : amount / LAMPORTS_PER_SOL}
                                onChange={(e) => inputAmount((e.target as HTMLInputElement).value)}
                            />
                        </div>

                        {/* Network status */}
                        <div className="flex flex-col items-center gap-[20px] mt-[34px]">
                            <div className="flex justify-center items-center gap-[50px]">
                                <div className={`w-[26px] h-[26px] rounded-full ${networkStatus == NetworkStatusEnum.Good ? 'bg-[#46FF78]' : 'bg-[#808080]'} blur-[1px]`}></div>
                                <div className={`w-[26px] h-[26px] rounded-full ${networkStatus == NetworkStatusEnum.Warning ? 'bg-[#FFA800]' : 'bg-[#808080]'} blur-[1px]`}></div>
                                <div className={`w-[26px] h-[26px] rounded-full ${networkStatus == NetworkStatusEnum.Bad ? 'bg-[#FF0000]' : 'bg-[#808080]'} blur-[1px]`}></div>
                            </div>

                            <div className="text-[14px] font-normal leading-[17px]">
                                {
                                    networkStatus == NetworkStatusEnum.Good && (
                                        'Solana network is stable.'
                                    )
                                }

                                {
                                    networkStatus == NetworkStatusEnum.Warning && (
                                        'Solana network is having some problems. Deposits and withdraws may fail.'
                                    )
                                }

                                {
                                    networkStatus == NetworkStatusEnum.Bad && (
                                        'Solana network is NOT stable, please avoid depositing and withdrawing for now.'
                                    )
                                }
                            </div>
                        </div>

                        {
                            fundType === FundTypeEnum.Deposit ? (
                                depositStatus === "none" ? (
                                    <button
                                        className="gradient-btn w-[120px] h-[40px] text-[20px] font-medium mt-[35px] transition duration-300 hover:transform hover:scale-110 opacity-100"
                                        onClick={handleFund}
                                        onMouseEnter={() => playHover()}
                                    >
                                        Confirm
                                    </button>
                                ) : depositStatus === "sent" ? (
                                    <button className="h-[40px] text-[20px] cursor-default font-medium mt-[35px]">
                                        Validating <ClipLoader size={13} color="#ffffff" />
                                    </button>
                                ) : depositStatus === "validated" ? (
                                    <button className="h-[40px] text-[20px] cursor-default font-medium mt-[35px]">
                                        <h3 className="status text-green-400 font-bold">Successfully Validated</h3>
                                    </button>
                                ) : (
                                    <button className="h-[40px] text-[20px] cursor-default font-medium mt-[35px]">Failed</button>
                                )
                            ) : (
                                withdrawStatus === "none" ? (
                                    <button
                                        className="gradient-btn w-[120px] h-[40px] text-[20px] font-medium mt-[35px] transition duration-300 hover:transform hover:scale-110 opacity-100"
                                        onClick={handleFund}
                                        onMouseEnter={() => playHover()}
                                    >
                                        Confirm
                                    </button>
                                ) : withdrawStatus === "withdrawing" ? (
                                    <div className="mt-[35px] font-medium">
                                        <h3>{`Withdrawing: ${((amount ?? 0) / LAMPORTS_PER_SOL).toLocaleString()} SOL`}</h3>
                                        <button disabled className="mt-5 gradient-btn px-[25px] h-[40px] text-[20px] cursor-default font-medium flex justify-center items-center gap-1">
                                            <span className="withdrawLabel">Confirming..</span>{" "}
                                            <ClipLoader size={13} color="#ffffff" />
                                        </button>
                                    </div>
                                ) : withdrawStatus === "withdrawn" ? (
                                    <div className="mt-[35px] font-medium status text-green-400">
                                        <strong>
                                            <h2 className="mb-6 ">
                                                {`Successfully Withdrawn: ${((amount ?? 0) / LAMPORTS_PER_SOL).toLocaleString()} SOL`}
                                            </h2>
                                        </strong>
                                        <a
                                            target="_blank"
                                            href={`https://solscan.io/tx/${withdrawTxHash}`}
                                            className="text-white underline transition-colors duration-300 hover:text-blue-500 focus:text-blue-500 active:text-blue-600"
                                        >
                                            Solscan External Link
                                        </a>


                                    </div>
                                ) : withdrawStatus === "unknown" ? (
                                    <div className="mt-[35px] font-medium">
                                        <h3>Please check your transactions.</h3>
                                        <h3>Open a ticket if you require support</h3>
                                    </div>
                                ) : (
                                    <h3 className="mt-[35px] font-medium">Failed</h3>
                                )
                            )
                        }

                        <button
                            className="mt-[21px] sm:hidden"
                            onClick={() => handleShowModal(false)}
                            onMouseEnter={() => playHover()}
                        >
                            <img src={arrowUpIcon} alt="arrow-up" />
                        </button>
                    </div>
                </div>
                <div className="overlay"></div>
            </div>
        </Slide>
    );
};

export default FundModal;