import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { shortName } from "../../utils";
import { GiftTypeEnum } from "../../enums";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import greenGiftIcon from "../../assets/gift-green.svg";
import awardIcon from "../../assets/award.svg";
import blueSolSymbol from "../../assets/sol-symbol-blue.svg";
import useSound from "use-sound";
// @ts-ignore
import Slide from "react-reveal/Slide";

interface IProps {
  show: boolean;
  giftId: string;
  giftSenderName: string | undefined;
  giftSenderPubkey: string;
  giftAmount: number;
  giftMessage: string;
  giftType: GiftTypeEnum;
  handleShow: (status: boolean) => void;
  handleCheckGift: (giftId: string) => Promise<void>;
}

const GiftModal = (props: IProps) => {
  const {
    show,
    giftId,
    giftSenderName,
    giftSenderPubkey,
    giftAmount,
    giftMessage,
    giftType,
    handleShow,
    handleCheckGift,
  } = props;
  const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });

  const giftsender = giftSenderName ?? giftSenderPubkey;

  useEffect(() => {
    if (show) {
      // document.body.style.overflow = "hidden"
    } else {
      // document.body.style.overflow = "visible"
    }
  }, [show]);

  return (
    <Slide top duration={500} when={show}>
      <div
        className="justify-center fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] sm:bg-black/50 z-[1040] overflow-y-auto"
        style={{ display: show ? "flex" : "none" }}
      >
        {/* Modal */}
        <div className="w-full h-full flex flex-col justify-center items-center mx-[25px] xl:pl-[155px] xl:pr-[336px]">
          {/* Modal Wrap */}
          <div
            className={`relative flex flex-col items-center w-full h-[150px] max-w-[365px] p-[41px_20px_20px]  border border-solid z-[1050] rounded-[10px] ${
              giftType == GiftTypeEnum.Tip
                ? "border-[#46FF78] shadow-[0px_0px_8px_#46FF78]"
                : "border-[#00A5FE]"
            }`}
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 165, 254, 0.05) 0%, rgba(0, 165, 254, 0.00) 38.73%), linear-gradient(90deg, rgba(0, 165, 254, 0.05) 0%, rgba(0, 165, 254, 0.00) 25.72%), linear-gradient(0deg, rgba(0, 165, 254, 0.05) 0%, rgba(0, 165, 254, 0.00) 24%), linear-gradient(270deg, rgba(0, 165, 254, 0.05) 0%, rgba(0, 165, 254, 0.00) 25.28%), #090C0E",
            }}
          >
            {/* Gift icon */}
            <div className="flex justify-center items-center w-full absolute top-[-28px]">
              <div
                className={`flex justify-center items-center w-[56px] h-[56px] rounded-full bg-[#11161B] border-2 border-solid ${
                  giftType == GiftTypeEnum.Tip
                    ? "border-[#46FF78]"
                    : "border-[#F7EFA8]"
                }`}
              >
                <img
                  src={giftType == GiftTypeEnum.Tip ? greenGiftIcon : awardIcon}
                  alt="gift-icon"
                  className={`${
                    giftType == GiftTypeEnum.Tip ? "w-[37px]" : "w-[29px]"
                  }`}
                />
              </div>
            </div>

            {/* Modal body */}
            <div className="w-full h-full">
              <div className="flex flex-col items-center w-full h-full">
                {/* Gift Amount */}
                <div className="flex justify-center items-center gap-[3px] text-[16px] font-inter font-bold leading-normal">
                  <span>
                    {giftType == GiftTypeEnum.Tip ? (
                      <>
                        <Link
                          to={`/user-stats/${giftSenderPubkey}`}
                          className="underline"
                        >
                          {shortName(giftsender, 10, 5)}
                        </Link>{" "}
                        gifted you{" "}
                        <span className="text-[#46FF78]">
                          {giftAmount / LAMPORTS_PER_SOL}
                        </span>
                      </>
                    ) : (
                      <>
                        You won{" "}
                        <span className="text-[#00a5fe]  text-[20px]">
                          {giftAmount / LAMPORTS_PER_SOL}
                        </span>
                      </>
                    )}
                  </span>
                  <img
                    src={blueSolSymbol}
                    alt="blue-sol-symbol"
                    className="w-[16px]"
                  />
                  <div>Wager NFT</div>
                </div>

                {/* Gift text */}
                <div className="grow flex justify-center items-center text-[12px] font-inter font-medium">
                  {giftMessage}
                </div>

                {/* Confirm button */}
                <button
                  className="flex justify-center items-center gift-model-button w-[100px] h-[25px] text-[10px] font-extrabold rounded-[10px] border border-solid border-[#00a5fe] "
                  onClick={() => handleCheckGift(giftId)}
                  onMouseEnter={() => playHover()}
                >
                  Thank You!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default GiftModal;
