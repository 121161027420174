import { Socket } from "socket.io-client"

enum SocketActionTypes {
    LOAD_RPS_SOCKET = 'LOAD_RPS_SOCKET',
    LOAD_DICE_SOCKET = 'LOAD_DICE_SOCKET',
    LOAD_DICE2_SOCKET = 'LOAD_DICE2_SOCKET',
    LOAD_USER_SOCKET = 'LOAD_USER_SOCKET',
    LOAD_LOTTERY_SOCKET = 'LOAD_LOTTERY_SOCKET',
    LOAD_COINFLIP_SOCKET = 'LOAD_COINFLIP_SOCKET',
    LOAD_MESSAGE_SOCKET = 'LOAD_MESSAGE_SOCKET',
    LOAD_SHOP_SOCKET = 'LOAD_SHOP_SOCKET',
    LOAD_ANNOUNCEMENT_SOCKET = 'LOAD_ANNOUNCEMENT_SOCKET',
}

interface LoadRpsSocketAction {
    type: SocketActionTypes.LOAD_RPS_SOCKET,
    payload: Socket
}

interface LoadDiceSocketAction {
    type: SocketActionTypes.LOAD_DICE_SOCKET,
    payload: Socket
}

interface LoadDice2SocketAction {
    type: SocketActionTypes.LOAD_DICE2_SOCKET,
    payload: Socket
}

interface LoadUserSocketAction {
    type: SocketActionTypes.LOAD_USER_SOCKET,
    payload: Socket
}

interface LoadLotterySocketAction {
    type: SocketActionTypes.LOAD_LOTTERY_SOCKET,
    payload: Socket
}

interface LoadCoinflipSocketAction {
    type: SocketActionTypes.LOAD_COINFLIP_SOCKET,
    payload: Socket
}

interface LoadMessageSocketAction {
    type: SocketActionTypes.LOAD_MESSAGE_SOCKET,
    payload: Socket
}

interface LoadShopSocketAction {
    type: SocketActionTypes.LOAD_SHOP_SOCKET,
    payload: Socket
}

interface LoadAnnouncementSocketAction {
    type: SocketActionTypes.LOAD_ANNOUNCEMENT_SOCKET,
    payload: Socket
}

type SocketsAction =
    LoadRpsSocketAction |
    LoadDiceSocketAction |
    LoadDice2SocketAction |
    LoadUserSocketAction |
    LoadLotterySocketAction |
    LoadCoinflipSocketAction |
    LoadMessageSocketAction |
    LoadShopSocketAction |
    LoadAnnouncementSocketAction;

export type Sockets = {
    user?: Socket,
    rps?: Socket,
    dice?: Socket,
    dice2?: Socket,
    lottery?: Socket,
    coinflip?: Socket,
    message?: Socket,
    shop?: Socket,
    announcement?: Socket,
};

const defaultSockets: Sockets = {
    user: undefined,
    rps: undefined,
    dice: undefined,
    dice2: undefined,
    lottery: undefined,
    coinflip: undefined,
    message: undefined,
    shop: undefined,
    announcement: undefined,
}

export default (state: Sockets = defaultSockets, action: SocketsAction): Sockets => {
    switch (action.type) {
        case SocketActionTypes.LOAD_RPS_SOCKET: {
            return { ...state, rps: action.payload }
        }
        case SocketActionTypes.LOAD_DICE_SOCKET: {
            return { ...state, dice: action.payload }
        }
        case SocketActionTypes.LOAD_DICE2_SOCKET: {
            return { ...state, dice2: action.payload }
        }
        case SocketActionTypes.LOAD_USER_SOCKET: {
            return { ...state, user: action.payload }
        }
        case SocketActionTypes.LOAD_LOTTERY_SOCKET: {
            return { ...state, lottery: action.payload }
        }
        case SocketActionTypes.LOAD_COINFLIP_SOCKET: {
            return { ...state, coinflip: action.payload }
        }
        case SocketActionTypes.LOAD_MESSAGE_SOCKET: {
            return { ...state, message: action.payload }
        }
        case SocketActionTypes.LOAD_SHOP_SOCKET: {
            return { ...state, shop: action.payload }
        }
        case SocketActionTypes.LOAD_ANNOUNCEMENT_SOCKET: {
            return { ...state, announcement: action.payload }
        }
        default:
            return state
    }
}